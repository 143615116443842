<template>
  <div class="pa-2">

    <page-card
        cardTitle="Page 11"
        cardTitleIcon="mdi-playlist-edit"
        cardSubtitle="Inside Back Cover"
    >

          <v-card-text>

            <p>This is your chance to say a final farewell, so for this page you need to write a last message to your guests and upload a photo and caption.</p>
            <p>The farewell should be two or three sentences at most. As well as a nice way to sign the booklet off, this is a good opportunity to remind your guests to contribute to your Wedding Wall, in case they have not already done so.</p>
            <p>Please be aware of the character limit for this section, shown below the box. When previewing this page please ensure all your text is included.</p>
            <p>Not sure what to say? Here are a couple of suggestions for you.  Feel free to copy and paste the contents into the text box.</p>
            <p class='font-weight-medium'>Example 1</p>
            <p class='font-italic px-2'>Thank you so much for being a part of our special day. We hope you have had a wonderful and memorable time. Don't forget to share your answers with us - we'd love to see what you have put. Please send us any photos from the day and evening celebrations so we can reminisce!</p>
            <p class='font-weight-medium'>Example 2</p>
            <p class='font-italic px-2'>We hope you had an amazing time celebrating with us. We are so happy you were able to be a part of our special day. Now let's eat too much food, drink too much drink, and party until the sun comes up! Oh, and don't forget to send us any photos from the day or evening. Enjoy!</p>
          

          </v-card-text>

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
          class="pa-4"
          :disabled="readOnlyMode"
      >

        <v-textarea
            v-model="farewell"
            :rules="farewellRules"
            label="Farewell Text"
            :counter="400"
            outlined
            @change="fieldValueChanged"
        />

            <v-card-text>
              <p>
                The image below is the shape of the photo required.
              </p>
            </v-card-text>

              <img
                class="mb-4"
                style="max-width: 350px; width: 100%;"
                src="@/assets/img/photo_sizing_w92mm_h68mm.jpg"
                alt="Image shape"
              />         

        <image-upload
            ref="imageUpload"
            sourceType="page"
            :sourceId=pageName
            fileInputLabel="Choose inside back cover image"
            class='pb-4'
        />

        <v-text-field
            v-model="caption"
            :rules="captionRules"
            label="Photo Caption"
            :counter="40"
            outlined
            @change="fieldValueChanged"
        />

        <div class="mt-8">
          <v-btn
              :disabled="!valid || readOnlyMode"
              type="submit"
              color="green"
              class="mr-4"
          >
            Save
          </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
        </div>

      </v-form>

    </page-card>

  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";

export default {
  name: "Page11",
  components: {
    ImageUpload,
    PageCard
  },
  mixins: [form],
  data: () => ({
    pageName: "p11",
    valid: true,
  }),
  computed: {
    farewell: {
      get: function () {
        // TODO: This is horribly fragile.
        // Create a getter that finds the object by searching for the "p2" key instead.
        return this.$store.state.order.pages[2].data.farewell
      },
      set(value) {
        this.$store.commit("setP11farewell", value)
      }
    },
    caption: {
      get: function () {
        // TODO: This is horribly fragile.
        // Create a getter that finds the object by searching for the "p2" key instead.
        return this.$store.state.order.pages[2].data.caption
      },
      set(value) {
        this.$store.commit("setP11caption", value)
      }
    },
  }
}
</script>
